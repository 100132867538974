import React from 'react'
import PropTypes from 'prop-types'
import { chakra } from '@chakra-ui/react'

const baseStyles = {
	position: 'absolute',
	right: '16px',
	top: '12px',
	w: '54px',
	h: '54px',
	content: '""',
	outline: 'none',
	border: 'none',
	borderRadius: 'full',
	backgroundColor: 'white',
	backgroundImage: '/assets/images/nav/toggle.svg',
	backgroundSize: '18px 12px',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	cursor: 'pointer',
	boxShadow: 'base',
}

const activeStyles = {
	backgroundImage: '/assets/images/nav/toggle-close.svg',
	backgroundSize: '14px 14px',
}

const Burger = ({ setNavToggle, isActive }) => {
	const styles = isActive ? { ...baseStyles, ...activeStyles } : baseStyles

	return <chakra.button onClick={setNavToggle} sx={styles} aria-label="Menu Button" aria-haspopup={true} />
}

Burger.propTypes = {
	/** Should the panel be visible/active? */
	isActive: PropTypes.bool.isRequired,
	/** Method callback to toggle the navigation */
	setNavToggle: PropTypes.func.isRequired,
}

Burger.defaultProps = {
	isActive: false,
}

export default Burger
